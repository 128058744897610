import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import {
  IconTextField,
  CustomParentBox,
  LeftBannerBoxParent,
  RightBannerBoxParent,
} from "../../features/CustomInputFields/CustomInputFields";
import "./CredMgmtForgotPassword.css";
import { useFormik } from "formik";
import { useState } from "react";
import { CredentialMgmtDataService } from "../../services/CredentialMgmtDataService";
import { _forgotPasswordSuccessMessage, _unknownError } from "../../helpers/StatusMessages/StatusMessages";
import { CenterLoadingSpinner } from "../Spinners/LoadSpinner";
import { useConfig } from "../../contexts/ConfigContext";
import StatusScreen from "../StatusScreen/StatusScreen";
import * as Yup from "yup";
import { validationSchema_email } from "../../helpers/ValidationSchema/ValidationSchema";
import { useTenant } from "../../contexts/TenantService";
import { useParams } from "react-router";
import LogoBox from "../LogoBox/LogoBox";

const CredMgmtForgotPassword = () => {
  const credentialService = CredentialMgmtDataService();
  const { tenant } = useParams();
  const [validStatus, setValidStatus] = useState({
    step: "Init",
  });
  const { tenantSvc } = useTenant();
  const { config } = useConfig();
  const [isLoading, setIsloading] = useState(false);
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object().shape({
      email: validationSchema_email.email,
    }),
    onSubmit: (values) => {
      setIsloading(true);
      credentialService
        .forgotPassword("/accounts/operations/forgot-password", values)
        .then((response) => {
          setIsloading(false);
          setValidStatus({
            status: response.status,
            step: "ForgotPassword",
            message: _forgotPasswordSuccessMessage,
          });
        })
        .catch((error) => {
          setIsloading(false);
          setValidStatus({
            status: error.response.status,
            message: _unknownError,
          });
        });
    },
  });
  if (!config) {
    <CenterLoadingSpinner />;
  }

  if (!validStatus) {
    return <CenterLoadingSpinner />;
  }
  if (validStatus.status === 400 || validStatus.status === 500) {
    return <StatusScreen status={validStatus} />;
  }

  if (validStatus.status === 200) {
    return <StatusScreen status={validStatus} />;
  }
  return (
    <CustomParentBox className="fpPage cred-mgmt">
      <Grid container spacing={1} sx={{ height: "100%" }}>
        <Grid item xs={7} sx={{ height: "100%", ml: "0", p: "0", display: { xs: "none", sm: "block" } }}>
          <LeftBannerBoxParent className="left-box-wrapper">
            <Box
              className={tenant}
              id="worldline"
              component="img"
              alt="CredentialManagementBanner"
              src={process.env.PUBLIC_URL + `/static/images/${tenant}/CredsManagementBanner.png`}
            />
          </LeftBannerBoxParent>
        </Grid>
        <Grid item sm={5} className="right-wrapper" id={tenant}>
          <RightBannerBoxParent>
            <LogoBox tenant={tenant} fallbackSrc={`${process.env.PUBLIC_URL}/static/images/default/banner_logo.png`} />
            {isLoading ? (
              <div>
                <CenterLoadingSpinner style={{ marginLeft: 0 }} />
              </div>
            ) : (
              <form>
                <Typography variant="h5" className="header-5">
                  Forgot Password
                </Typography>
                <Stack sx={{ mt: "30px" }}>
                  <label className="input-label">EMAIL ADDRESS</label>
                  <IconTextField
                    name="email"
                    placeholder="username@worldline.com"
                    label=""
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Typography variant="body2" color="error" gutterBottom>
                      {formik.errors.email}
                    </Typography>
                  )}
                  <Stack
                    direction="row"
                    sx={{ justifyContent: "space-between", alignItems: "center", marginTop: "25px" }}
                  >
                    <a
                      style={{ color: "#414141" }}
                      className="back-to-login"
                      rel="noopener noreferrer"
                      href={tenantSvc?.authExitUrl}
                    >
                      &lt; Back to login
                    </a>
                    <Button
                      disabled={!formik.values.email}
                      className="submit-btn"
                      label="Submit"
                      icon={null}
                      onClick={formik.handleSubmit}
                    >
                      Submit
                    </Button>
                  </Stack>
                </Stack>
              </form>
            )}
          </RightBannerBoxParent>
        </Grid>
      </Grid>
    </CustomParentBox>
  );
};

export default CredMgmtForgotPassword;
