import PropTypes from "prop-types";
import { createContext, useState, useContext } from "react";

const HotkeysContext = createContext(undefined);

export const HotkeysProvider = ({ children }) => {
  const [hotkeysEnabled, setHotkeysEnabled] = useState(true);

  return <HotkeysContext.Provider value={{ hotkeysEnabled, setHotkeysEnabled }}>{children}</HotkeysContext.Provider>;
};

export const useHotkeysContext = () => {
  const context = useContext(HotkeysContext);
  if (context === undefined) {
    throw new Error("useHotkeysContext must be used within a HotkeysProvider");
  }
  return context;
};
HotkeysProvider.propTypes = {
  children: PropTypes.node,
};
