import { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid } from "@mui/material";
import { MRT_GlobalFilterTextField } from "material-react-table";
import DownloadIcon from "@mui/icons-material/Download";
import { BaseTable } from "../BaseTable";
import { CSVLink } from "react-csv";
import { CurrentDate, dateFormat } from "../../helpers";
import { useParams } from "react-router-dom";
import { renameKey } from "../../helpers/FormatDateTime/FormatDateTime";
import { useHotkeys } from "react-hotkeys-hook";

const UserLastLoginTable = ({ data, setTotalData, dataLoading, csvData }) => {
  const { tenant } = useParams();
  const downloadReportRef = useRef(null);
  useEffect(() => {
    if (Array.isArray(data)) {
      setTotalData({ before: data.length, after: data.length });
    }
  }, [data, setTotalData]);
  const sortByLastLoginTime = (arr) => arr?.sort((a, b) => new Date(b.lastLoginTime) - new Date(a.lastLoginTime));

  const sortedData = sortByLastLoginTime(data);
  const sortedCsvData = sortByLastLoginTime(csvData);

  const transformedData = renameKey(sortedCsvData, "lastLoginTime", `lastLoginTime (${dateFormat?.DATE_TIME_FORMAT})`);
  const currentDate = CurrentDate();

  const columns = useMemo(
    () => [
      {
        accessorKey: "lastLoginTime",
        header: "LAST LOGIN TIME",
        size: 150,
        Cell: ({ cell }) => cell.getValue(),
        sortingFn: (rowA, rowB, columnId) => {
          const a = new Date(rowA.getValue(columnId)).getTime();
          const b = new Date(rowB.getValue(columnId)).getTime();
          return a < b ? -1 : a > b ? 1 : 0;
        },
      },
      {
        accessorKey: "username",
        header: "USER NAME",
        size: 180,
        Cell: ({ cell }) => <div className="padding-lr-10">{cell.getValue()}</div>,
      },
      {
        accessorKey: "organization",
        header: "ORGANIZATION",
        size: 150,
        Cell: ({ cell }) => <div className="padding-lr-10">{cell.getValue()}</div>,
      },
    ],
    []
  );
  useHotkeys("n", (event) => {
    event.preventDefault();
    if (downloadReportRef.current) {
      downloadReportRef.current.link.click();
    }
  });
  const renderTopToolbar = ({ table }) => (
    <Box className="flex_box-space">
      <Grid container className="UserListContainer">
        <Grid item xs={9}></Grid>
        <Grid item xs={2} className="global-search-textbox-wrapper">
          <MRT_GlobalFilterTextField table={table} />
        </Grid>
        {!dataLoading && Array.isArray(data) && data.length > 0 && (
          <Grid item xs={1} className="download-btn-wrapper">
            <CSVLink
              data={transformedData}
              filename={`WorldlineCIAM-${tenant.toUpperCase()}-UserLastLoginReport-Standard-${currentDate}.csv`}
              style={{ textDecoration: "none" }}
              ref={downloadReportRef}
            >
              <Button variant="contained" endIcon={<DownloadIcon />} className="report-btn">
                Download
              </Button>
            </CSVLink>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  const customTableOptions = {
    columns,
    data: sortedData || [],
    renderTopToolbar,
    enableExpanding: false,
    enablePagination: true,
    paginationDisplayMode: "pages",
    enableRowNumbers: false,
    enableSorting: true,
    muiPaginationProps: {
      className: "paginationClasses",
      shape: "rounded",
      variant: "outlined",
      rowsPerPageOptions: [5, 10, 25, 50],
      showFirstButton: false,
      showLastButton: false,
    },
    columnResizeDirection: "ltr",
    muiTablePaperProps: ({ table }) => ({
      style: {
        top: table.getState().isFullScreen ? 100 : 0,
      },
    }),
    muiSkeletonProps: {
      animation: "wave",
    },
    initialState: {
      sorting: [
        {
          id: "lastLoginTime",
          desc: true,
        },
      ],
      showGlobalFilter: true,
      columnVisibility: {
        searchableRoles: false,
        searchableSystemRoles: false,
        systemRoles: false,
      },
    },
    enableColumnActions: false,
    muiTableHeadCellProps: {
      sx: {
        "& .Mui-TableHeadCell-Content-Actions": {
          display: "none",
        },
      },
    },
  };

  if (!data || data == undefined || !Array.isArray(data) || data.length === 0) {
    return <div />;
  }
  return !dataLoading && <BaseTable setTotalData={setTotalData} customTableOptions={customTableOptions} />;
};

UserLastLoginTable.propTypes = {
  data: PropTypes.array,
  setTotalData: PropTypes.func,
  dataLoading: PropTypes.bool,
  rawData: PropTypes.array,
  cell: PropTypes.object,
  csvData: PropTypes.array,
};

export default UserLastLoginTable;
