import {
  Grid,
  Typography,
  Avatar,
  TextField,
  Tooltip,
  Button,
  Toolbar,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { stringToColor } from "../UserNameAvatar/UserNameAvatar";
import { stringAvatar } from "../UserNameAvatar/UserNameAvatar";
import { Box } from "@mui/system";
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router";
import InfoIcon from "@mui/icons-material/Info";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./Profile.css";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  validationSchema_familyName,
  validationSchema_givenName,
  validationSchema_phone,
} from "../../helpers/ValidationSchema/ValidationSchema";
import UserServiceProvider from "../../services/UserServiceProvider/UserServiceProvider";
import { UseIdentityUser } from "../../helpers/UseIdentityUser";
import { DataContext } from "../../contexts/DataContext";
import { useContext } from "react";
import ChangePasswordModal from "../ChangePasswordModal/ChangePasswordModal";
import UserProfileSuccessPopUp from "../../features/UserProfileSuccessPopUp/UserProfileSuccessPopUp";
import { UserProfileSkeleton } from "../UserProfileSkeleton";
import { useTenant } from "../../contexts/TenantService";
import { HandleMoreInfoState, OwnRolesPermissions } from "../../helpers";
import { CreatedByInfo, ModifiedByInfo } from "../MoreInfo/MoreInfo";
import { UserRolesEntities } from "../../helpers";
import { Roles } from "../SecApp/Roles";
import { useAuth } from "../../contexts/AuthContext";
import handleApiError from "../../helpers/HandleapiError/HandleapiError";
import LeftDrawerLayout from "../LeftDrawerLayout/LeftDrawerLayout";
function Profile({ config }) {
  const userService = UserServiceProvider();
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [passwordChangeStatus, setPasswordChangeStatus] = useState(null);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const { setSuccessUserNameProfile } = useContext(DataContext);
  const { isMoreInfoVisible, toggleMoreInfo } = HandleMoreInfoState();
  const { identityUser } = UseIdentityUser();
  const { tenant } = useParams();
  const [user, setUser] = useState(identityUser);
  const navigate = useNavigate();
  const userURL = `/ciam/v1/${tenant}/users`;
  const { tenantSvc } = useTenant();
  const telephoneInfoTip =
    "The telephone number must conform to the international standards. Accepted formats are 18005551234, (800)5551234, 8005551234, +1 800 555 1234, +18005551234, 0018005551234, 18005551234, 1-800-555-1234 or 18005551234. Max length allowed 20.";
  const [totalRoles, setTotalRoles] = useState({ before: 0, after: 0 });
  const [totalEntities, setTotalEntities] = useState({ before: 0, after: 0 });

  const initialUserData = {
    name: identityUser?.name,
    organization: identityUser?.organization,
    email: identityUser?.email,
    active: identityUser?.active,
    givenName: identityUser?.givenName,
    familyName: identityUser?.familyName,
    phone: identityUser?.phone,
    id: identityUser?.id,
    organizationName: identityUser?.organizationName,
    roles: identityUser?.roles,
  };
  const fetchProfile = async () => {
    try {
      const response = await userService.getUserProfile("profile");
      setUser(response?.data);
    } catch (error) {
      const error_msg = handleApiError(error);
      setError(error_msg);
    }
  };
  const handleMoreInfoClick = useCallback(async () => {
    toggleMoreInfo();

    await fetchProfile();
  }, [user, fetchProfile]);
  const { authService } = useAuth();
  const formik = useFormik({
    initialValues: initialUserData,
    validationSchema: Yup.object().shape({
      givenName: validationSchema_givenName.givenName,
      familyName: validationSchema_familyName.familyName,
      phone: validationSchema_phone.phone,
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      userService
        .UpdateUserProfile("users/profile", values)
        .then((response) => {
          setSuccess(true);
          fetchProfile();
          setSuccessUserNameProfile(response?.data?.name);
          setTimeout(() => {
            setSubmitting(false);
          }, 500);
        })
        .catch((error) => {
          const errormessage = handleApiError(error);
          setError(true);
          setGlobalErrorMsg(errormessage);
          setTimeout(() => {
            setSubmitting(false);
          }, 500);
        });
    },
  });

  const handlePasswordChangeStatus = (status) => {
    setPasswordChangeSuccess(true);
    setPasswordChangeStatus(status);
  };

  useEffect(() => {
    let errorTimeout;
    let successTimeout;
    let passwordChangeTimeout;

    if (error) {
      errorTimeout = setTimeout(() => {
        setError(false);
        setGlobalErrorMsg("");
      }, 4000);
    }

    if (success) {
      successTimeout = setTimeout(() => {
        setSuccess(false);
        setSuccessUserNameProfile("");
      }, 4000);
    }

    if (passwordChangeSuccess) {
      passwordChangeTimeout = setTimeout(() => {
        setPasswordChangeSuccess(false);
        setPasswordChangeStatus("");
      }, 2000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
      clearTimeout(passwordChangeTimeout);
    };
  }, [error, success, passwordChangeStatus]);

  const isFormchanged =
    initialUserData.givenName === formik.values.givenName &&
    initialUserData.familyName === formik.values.familyName &&
    initialUserData.phone === formik.values.phone;

  let { roleSet, entitySet } = UserRolesEntities(identityUser?.roles, tenantSvc?.featureToggle?.isEntityPresent);
  if (!identityUser) {
    return <UserProfileSkeleton />;
  }
  return (
    <>
      <LeftDrawerLayout isLeftDrawerOpen={isLeftDrawerOpen} setIsLeftDrawerOpen={setIsLeftDrawerOpen}>
        <div className="profileContainer">
          <Grid container spacing={1}>
            <Grid md={3} sx={{ maxWidth: "20%" }} className="SuccessErrorMessageGrid">
              {success && <UserProfileSuccessPopUp />}
              {error && <div className="global-error-msg">{globalErrorMsg}</div>}
            </Grid>
          </Grid>
        </div>
        <Typography
          variant="h4"
          style={isLeftDrawerOpen ? { marginLeft: "13vw" } : { marginLeft: "4vw" }}
          sx={{ marginTop: "1vw", marginBottom: "1vw" }}
        >
          Profile
        </Typography>
        <Paper
          className="userProfileCard"
          elevation={0}
          data-testid="profile-page"
          style={isLeftDrawerOpen ? { marginLeft: "13vw", width: "82vw" } : { marginLeft: "4vw", width: "91vw" }}
        >
          {passwordChangeStatus && passwordChangeStatus?.code == 200 && (
            <div className="success-message">{passwordChangeStatus?.message}</div>
          )}
          {passwordChangeStatus && passwordChangeStatus?.code == 400 && (
            <div className="error-message">{passwordChangeStatus?.message}</div>
          )}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(90deg)",
                },
                flexDirection: "row-reverse",
              }}
            >
              <h3>Account Details</h3>
            </AccordionSummary>
            <AccordionDetails>
              <form>
                <Box sx={{ flexGrow: 1, padding: "15px 30px" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={3} lg={2} sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar
                        src={process.env.PUBLIC_URL + "/static/images/avatar/1.jpg"}
                        sx={{
                          width: { xs: 150, lg: 180 },
                          height: { xs: 150, lg: 180 },
                          bgcolor: stringToColor(`${identityUser?.givenName} ${identityUser?.familyName}`),
                          textTransform: "uppercase",
                          fontSize: 64,
                        }}
                      >
                        {stringAvatar(identityUser?.givenName, identityUser?.familyName)}
                      </Avatar>
                    </Grid>
                    <Grid item xs={9} lg={10} sx={{ mt: "0px" }}>
                      <Grid container spacing={1} sx={{ mb: "20px" }}>
                        <Grid item xs={8} md={8} lg={3}>
                          <Typography variant="caption" display="block" className="label_header">
                            USER NAME
                          </Typography>
                          <Typography>
                            <TextField
                              className="inputProfile"
                              name="name"
                              value={identityUser?.name}
                              disabled
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  backgroundColor: "#fff",
                                },
                              }}
                              label=""
                              sx={{
                                backgroundColor: "#fff",
                                input: { color: "red", padding: "0" },
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={4} md={4} lg={3}>
                          <Typography variant="caption" display="block" className="label_header">
                            ORGANIZATION
                          </Typography>
                          <Typography>
                            <TextField
                              className="inputProfile"
                              name="organization"
                              value={identityUser?.organizationName}
                              disabled
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  backgroundColor: "#fff",
                                },
                              }}
                              label=""
                              sx={{
                                width: "100%",
                                backgroundColor: "#fff",
                                input: { color: "red", padding: "0" },
                                "& .MuiInputBase-input.Mui-disabled": {
                                  WebkitTextFillColor: "#000000",
                                },
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={8} md={8} lg={4}>
                          <Typography variant="caption" display="block" className="label_header">
                            EMAIL ADDRESS
                          </Typography>
                          <TextField
                            className="inputProfile"
                            name="email"
                            value={identityUser?.email}
                            disabled
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                backgroundColor: "#fff",
                              },
                            }}
                            label=""
                            sx={{
                              width: "100%",
                              backgroundColor: "#fff",
                              input: { color: "red", padding: "0" },
                              "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                              },
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} md={4} lg={2} className="StatusFiled">
                          <Typography variant="caption" display="block" className="label_header">
                            STATUS
                          </Typography>
                          <Typography
                            label="STATUS"
                            className={
                              identityUser?.active == true ? "customStatusClassActive" : "customStatusClassInactive"
                            }
                          >
                            {identityUser?.active == true ? "Active" : "Inactive"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} className="inputboxProfile">
                        <Grid item xs={4} lg={3}>
                          <Typography variant="caption" display="block" className="label_header">
                            GIVEN NAME
                          </Typography>
                          <TextField
                            name="givenName"
                            variant="outlined"
                            label=""
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.givenName}
                          />
                          {formik.touched.givenName && formik.errors.givenName ? (
                            <div className="error-msg">{formik.errors.givenName}</div>
                          ) : null}
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Typography variant="caption" display="block" className="label_header">
                            FAMILY NAME
                          </Typography>
                          <TextField
                            name="familyName"
                            variant="outlined"
                            label=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.familyName}
                          />
                          {formik.touched.familyName && formik.errors.familyName ? (
                            <div className="error-msg">{formik.errors.familyName}</div>
                          ) : null}
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Typography variant="caption" display="block" className="label_header">
                            TELEPHONE
                            <Tooltip title={telephoneInfoTip}>
                              <InfoIcon
                                sx={{
                                  width: 14,
                                  height: 14,
                                  color: "#2D8282",
                                  verticalAlign: "middle",
                                  mb: 0.3,
                                  ml: "5px",
                                }}
                              />
                            </Tooltip>
                          </Typography>
                          <TextField
                            name="phone"
                            variant="outlined"
                            label=""
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="error-msg">{formik.errors.phone}</div>
                          ) : null}
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Toolbar
                  className="UserProfileCustomToolBar"
                  sx={{
                    backgroundColor: "#fff",
                    justifyContent: "flex-end",
                    display: "flex",
                    borderTop: "1px solid #DCE3F3",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ borderRadius: "20px", mr: "40px", pl: "35px", pr: "35px" }}
                    className="cancel-btn"
                    onClick={() => navigate(userURL)}
                  >
                    Cancel
                  </Button>
                  {formik.isSubmitting ? (
                    <LoadingButton
                      loading
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="outlined"
                      className="loaderButton"
                    >
                      Save
                    </LoadingButton>
                  ) : (
                    <Button
                      label="Save"
                      type="submit"
                      icon={null}
                      variant="contained"
                      className="save-btn profile-save"
                      disabled={isFormchanged}
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                    >
                      Save
                    </Button>
                  )}
                </Toolbar>
              </form>
            </AccordionDetails>
          </Accordion>

          {tenantSvc?.featureToggle?.showOwnRoles && authService.authorize([Roles.PROFILE_MT, Roles.PROFILE]) && (
            <>
              <Accordion className="container-paper">
                <AccordionSummary
                  expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                      transform: "rotate(90deg)",
                    },
                    flexDirection: "row-reverse",
                  }}
                  className="wrapper-paper"
                >
                  <h3>Assigned Roles - {totalRoles?.after}</h3>
                </AccordionSummary>

                <AccordionDetails>
                  <OwnRolesPermissions data={roleSet} setTotalRoles={setTotalRoles} isEntityTable={false} />
                </AccordionDetails>
              </Accordion>
              {tenantSvc?.featureToggle?.isEntityPresent && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                        transform: "rotate(90deg)",
                      },
                      flexDirection: "row-reverse",
                    }}
                  >
                    <h3>Assigned Entities - {totalEntities?.after}</h3>
                  </AccordionSummary>
                  <AccordionDetails className="entity-assigned-panel">
                    <OwnRolesPermissions data={entitySet} setTotalEntities={setTotalEntities} isEntityTable={true} />
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          )}
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(90deg)",
                },
                flexDirection: "row-reverse",
              }}
            >
              <h3>Credential Management</h3>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Grid item xs={10}>
                  <Grid container spacing={1}>
                    <Grid item xs={8} lg={9}>
                      <ChangePasswordModal
                        config={config}
                        open={open}
                        onClose={handleClose}
                        setOpen={setOpen}
                        onPasswordChangeStatus={handlePasswordChangeStatus}
                        organization={identityUser?.organization}
                      />
                      <Typography>
                        <span>
                          <Button onClick={handleOpen} className="change-password-label">
                            Change password
                          </Button>
                          <KeyboardArrowRightIcon className="change-password-arrow" />
                        </span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ marginTop: 2 }} className="more-info-accordian">
            <AccordionSummary
              onClick={handleMoreInfoClick}
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(90deg)",
                },
                flexDirection: "row-reverse",
              }}
            >
              <h3>More Info</h3>
            </AccordionSummary>
            <AccordionDetails>
              {isMoreInfoVisible && (
                <div className="more-info-container">
                  <CreatedByInfo id={identityUser?.id} user={identityUser} />
                  <ModifiedByInfo id={identityUser?.id} user={user} />
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </Paper>
      </LeftDrawerLayout>
    </>
  );
}
Profile.propTypes = {
  config: PropTypes.object,
};
export default Profile;
