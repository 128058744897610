import { MRT_ShowHideColumnsButton, MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, Button, Grid, Typography } from "@mui/material";
import "./UserListTable.css";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import useGetUsersList from "../../hooks/useGetUsersList/useGetUsersList";
import { useNavigate, useParams } from "react-router";
import { useState, useEffect } from "react";
import { ListUsersBodySkeleton } from "../ListUsersBodySkeleton";
import { GeneralColumns } from "../../helpers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import handleApiError from "../../helpers/HandleapiError/HandleapiError";
import LeftDrawerLayout from "../LeftDrawerLayout/LeftDrawerLayout";
function ListData() {
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { tenant } = useParams();
  const { data: allUsers, isError, isFetchingUsers, isLoadingUsers, error } = useGetUsersList();
  const UserListColumns = GeneralColumns();
  const [columnFilters, setColumnFilters] = useState([]);
  const [total, setTotal] = useState(allUsers?.data?.length);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const [processedData, setProcessedData] = useState([]);
  useEffect(() => {
    setTotal(allUsers?.data?.length);
    setProcessedData(
      allUsers?.data?.map((item) => ({
        ...item,
        createTimestamp: item.createTimestamp?.endsWith("Z") ? item.createTimestamp.slice(0, -1) : item.createTimestamp,
        modifyTimestamp: item.modifyTimestamp?.endsWith("Z") && item.modifyTimestamp.slice(0, -1),
      }))
    );
  }, [allUsers, allUsers?.data, isLoadingUsers, isFetchingUsers]);
  useEffect(() => {
    if (isError) {
      const errorMsg = handleApiError(error);
      setGlobalErrorMsg(errorMsg);
    }
  }, [error, allUsers]);
  useEffect(() => {
    setTotal(table.getFilteredRowModel()?.rows?.length);
  }, [columnFilters]);

  const table = useMaterialReactTable({
    columns: UserListColumns ?? [],
    onColumnFiltersChange: setColumnFilters,
    data: processedData ?? [],
    paginationDisplayMode: "pages",
    initialState: { showColumnFilters: true },
    state: {
      columnFilters,
      showAlertBanner: isError,
    },
    muiPaginationProps: {
      className: "paginationClasses",
      shape: "rounded",
      variant: "outlined",
      rowsPerPageOptions: [5, 10, 25, 50],
      showFirstButton: false,
      showLastButton: false,
    },

    muiToolbarAlertBannerProps: isError ? { color: "error", children: t("list_user.data.error") } : undefined,
    muiFilterTextFieldProps: {
      placeholder: "Search",
      sx: { m: "0.5rem 0", width: "100%" },
      variant: "outlined",
    },
    muiTableHeadCellProps: {
      sx: {
        fontFamily: "'Nunito', sans-serif",
      },
    },

    muiTableBodyCellProps: {
      sx: {
        fontFamily: "'Nunito', sans-serif",
      },
    },

    renderTopToolbar: ({ table }) => (
      <Box className="flex_box-space">
        <Grid container spacing={1} className="UserListContainer">
          <Grid item xs={4} sm={4} md={4}>
            <Typography variant="h5" sx={{ marginTop: "0.5vw" }}>
              <b> {t("list_user.heading")} </b>
              {!isLoadingUsers && !isFetchingUsers && (
                <span>
                  - {total}&nbsp;
                  {total == 1 ? t("list_user.user.record") : t("list_user.users.records")}
                </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={4} md={2}></Grid>
          <Grid item xs={3} sm={4} md={6} className="flex-right">
            <Box className="flex_box-center">
              <span className="show-hide-col">
                <VisibilityOutlinedIcon className="selectColumnsIcon-show-eye" />
                <MRT_ShowHideColumnsButton table={table} data-testid="show-hide-btn" />
                <KeyboardArrowDownOutlinedIcon className="selectColumnsIcon-arrow-down" />
              </span>

              <Button
                variant="contained"
                className="add-user-btn"
                onClick={() => navigate(`/ciam/v1/${tenant}/users/create`)}
              >
                Add User
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    ),
  });
  if (isLoadingUsers && isFetchingUsers) {
    return <ListUsersBodySkeleton />;
  }

  return (
    <>
      {isError && (
        <div className="error_container list_user">
          <Grid md={3} sx={{ maxWidth: "20%" }} className="ErrorMessageGrid">
            {error && <div className="global-error-msg">{globalErrorMsg}</div>}
          </Grid>
        </div>
      )}
      <LeftDrawerLayout isLeftDrawerOpen={isLeftDrawerOpen} setIsLeftDrawerOpen={setIsLeftDrawerOpen}>
        <div
          className="user-list-table"
          style={isLeftDrawerOpen ? { marginLeft: "11vw", width: "85vw" } : { marginLeft: "1vw", width: "95.5vw" }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MaterialReactTable table={table} enableColumnFilters />{" "}
          </LocalizationProvider>
        </div>
      </LeftDrawerLayout>
    </>
  );
}

const queryClient = new QueryClient();

const UserListTable = () => (
  <QueryClientProvider client={queryClient}>
    <ListData />
  </QueryClientProvider>
);

ListData.propTypes = {
  cell: PropTypes.object,
};

export default UserListTable;
