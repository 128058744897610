import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { LeftDrawerList } from "../LeftDrawerList";

const LeftDrawerLayout = ({ isLeftDrawerOpen, setIsLeftDrawerOpen, children }) => {
  return (
    <Box sx={{ flexDirection: "row", display: "flex" }}>
      <div className="right-container">
        <LeftDrawerList isLeftDrawerOpen={isLeftDrawerOpen} setIsLeftDrawerOpen={setIsLeftDrawerOpen} />
        {children}
      </div>
    </Box>
  );
};

LeftDrawerLayout.propTypes = {
  isLeftDrawerOpen: PropTypes.bool.isRequired,
  setIsLeftDrawerOpen: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default LeftDrawerLayout;
