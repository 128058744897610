/* eslint-disable react/prop-types */

import { useContext, useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Paper,
  Grid,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import roleUtility from "../../helpers/RoleUtility/RoleUtility";
import { UserServiceProvider } from "../../services/UserServiceProvider/UserServiceProvider";
import "./EditUserComponent.css";
import { useNavigate, useParams } from "react-router";
import { Container } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  nameInfoTooltip,
  telephoneInfoTip,
  twoFAInfoToolTip,
  userNameInfoToppTip,
} from "../../helpers/TooltipsInfo/TooltipsInfo";
import { DataContext } from "../../contexts/DataContext";
import { LoadingButton } from "@mui/lab";
import OrganizationTreeSturctureMeta from "../../helpers/OrganizationTreeSturctureMeta/OrganizationTreeSturctureMeta";
import DualListBox from "react-dual-listbox";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  validationSchema_email,
  validationSchema_familyName,
  validationSchema_givenName,
  validationSchema_phone,
} from "../../helpers/ValidationSchema/ValidationSchema";
import { RoleServiceProvider } from "../../services";
import { OrganizationList } from "../../hooks/OrganizationList";
import { useAuth } from "../../contexts/AuthContext";
import { getUserOnboardingDetails } from "../../services/TwoFaModalApi/TwoFaModalApi";
import OnBoardPrecheckContent from "../OnBoardPrecheckContent/OnBoardPrecheckContent";
import AddEditUserSkeleton from "../AddEditUserSkeleton/AddEditUserSkeleton";
import DualListBoxSkeleton from "../DualListBoxSkeleton/DualListBoxSkeleton";
import { useTenant } from "../../contexts/TenantService";
import { handleDualListBoxClick, HandleMoreInfoState } from "../../helpers";
import { UseIdentityUser } from "../../helpers/UseIdentityUser";
import { CreatedByInfo, ModifiedByInfo } from "../MoreInfo/MoreInfo";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import handleApiError from "../../helpers/HandleapiError/HandleapiError";
import LeftDrawerLayout from "../LeftDrawerLayout/LeftDrawerLayout";

const EditUserComponent = () => {
  const userService = UserServiceProvider();
  const roleService = RoleServiceProvider();
  const OrgList = OrganizationList();
  const [success, setSuccess] = useState(false);
  const [awaitResponse, setAwaitResponse] = useState({ isLoading: false, msg: "" });
  const [confirmResetModal, setConfirmResetModal] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [entitiesOptions, setEntitiesOptions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  console.log(isSaveEnabled); // to be used on save button
  const [error, setError] = useState(false);
  const [globalErrorMsg, setGlobalErrorMsg] = useState("");
  const { setSuccessUserNameEdit } = useContext(DataContext);
  const { id } = useParams("id");
  const [userData, setUserData] = useState({});
  const [roles, setRoles] = useState([]);
  const { identityUser } = UseIdentityUser();
  const [onBoardingError, setOnBoardingError] = useState(false);
  const [identityUserClass, setIdentityUserClass] = useState(false);
  const [preCheckStatus, setPrecheckStatus] = useState({
    error: false,
    statusCode: 0,
    success: false,
    data: {},
    userData: userData,
  });
  useEffect(() => {
    userService
      ?.getUserByID("users", id)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((error) => {
        setError(true);
        const errorMsg = handleApiError(error);
        setGlobalErrorMsg(errorMsg);
      });
  }, [error]);

  useEffect(() => {
    if (userData) {
      setSelectedRoles(userData?.roles);
      !loadingRoles && setSelectedEntities(userData?.roles);
    }
  }, [userData]);
  const { isMoreInfoVisible, toggleMoreInfo } = HandleMoreInfoState();

  const [loadingRoles, setLoadingRoles] = useState(true);
  const { tenantSvc } = useTenant();
  useEffect(() => {
    setLoadingRoles(true);
    if (userData?.organization) {
      roleService
        .getRolesByOrganization("roles", userData?.organization)
        .then((response) => {
          setRoles(response);
          setLoadingRoles(false);
        })
        .catch((error) => {
          setError(true);
          const errorMsg = handleApiError(error);
          setGlobalErrorMsg(errorMsg);
        });
    }
  }, [userData?.organization, error]);
  useEffect(() => {
    if (identityUser?.id == id) {
      setIdentityUserClass(true);
    } else {
      setIdentityUserClass(false);
    }
  }, [identityUser]);
  useEffect(() => {
    let errorTimeout;
    let successTimeout;

    if (error) {
      errorTimeout = setTimeout(() => {
        setError(false);
        setGlobalErrorMsg("");
      }, 5000);
    }

    return () => {
      clearTimeout(errorTimeout);
      clearTimeout(successTimeout);
    };
  }, [error, success]);

  let rolesHavingNames = [];
  useEffect(() => {
    if (userData && userData?.organization && roles?.data) {
      let nonDefaultRoleOptions = [];
      let defaultRoleOptions = [];

      roles?.data?.map((role) => {
        if (Object.prototype.hasOwnProperty.call(role, "name")) {
          rolesHavingNames.push(role);
        }
      });

      const rolesEntityList = roleUtility.constructRolesAndEntitiesSet(
        rolesHavingNames,
        tenantSvc?.featureToggle?.isEntityPresent
      );

      nonDefaultRoleOptions = userData.roles.filter((r) => rolesEntityList?.rolesOnly?.some((ro) => ro.value === r));
      defaultRoleOptions = rolesHavingNames
        .filter((r) => r.defaultRole == true)
        .map((a) => {
          return a.id;
        });

      setRoleOptions(rolesEntityList?.rolesOnly);
      setEntitiesOptions(rolesEntityList?.entitiesOnly);
      setSelectedRoles(
        nonDefaultRoleOptions.concat(
          defaultRoleOptions.filter(function (item) {
            return !nonDefaultRoleOptions.includes(item);
          })
        )
      );

      setSelectedEntities(userData.roles.filter((r) => rolesEntityList?.entitiesOnly?.some((eo) => eo.value === r)));
    }
  }, [userData, roles?.data]);

  let initialUserData = {
    id: id,
    name: userData?.name ?? "",
    givenName: userData?.givenName ?? "",
    familyName: userData?.familyName ?? "",
    email: userData?.email ?? "",
    phone: userData?.phone ?? "",
    roles: userData?.roles ?? [],
    organization: userData?.organization ?? "",
    active: userData?.active ? true : false,
    description: userData?.description ?? "",
    entities: userData?.roles ?? [],
  };
  const { tenant } = useParams();
  const [clickedRoleValue, setClickedRoleValue] = useState("");
  const [clickedEntityValue, setClickedEntityValue] = useState("");

  const userURL = `/ciam/v1/${tenant}/users`;
  const formik = useFormik({
    initialValues: initialUserData,
    validationSchema: Yup.object().shape({
      givenName: validationSchema_givenName.givenName,
      familyName: validationSchema_familyName.familyName,
      phone: validationSchema_phone(tenantSvc?.featureToggle?.isPhoneRequired).phone(),
      email: validationSchema_email.email,
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      values.roles = selectedRoles.concat(selectedEntities);
      userService
        .updateUser("users", values, id)
        .then(() => {
          setSuccess(true);
          setSuccessUserNameEdit(userData?.name);
          navigate(userURL);
          setTimeout(() => {
            setSubmitting(false);
          }, 5000);
        })
        .catch((error) => {
          const errormessage = handleApiError(error);
          setError(true);
          setGlobalErrorMsg(errormessage);
          setTimeout(() => {
            setSubmitting(false);
          }, 5000);
        });
    },
  });

  const theme = `${tenant}-theme`;
  const navigate = useNavigate();
  const { authService } = useAuth();
  const precheckURL = tenantSvc?.otpPreCheckUrlPath;
  const tenantIdentifier = tenantSvc?.tenantId;

  const token = authService?.getAccessToken();

  let org;
  let userNameInfo = "";
  if (OrgList?.allOrgs?.data) {
    org = OrgList?.allOrgs?.data.find((o) => o.id === userData?.organization);

    if (tenantSvc?.tenantId !== "psa" && org?.usernamePolicy) {
      userNameInfo = org.usernamePolicy?.userNamePatternInfo;
    }
  }

  const handle2FAModalOpen = () => {
    setAwaitResponse({ isLoading: true, msg: "Checking User's On-Boarding status..." });
    if (token) {
      setShowModal(true);
      getUserOnboardingDetails(`${tenantSvc?.apiUrl}/${tenantIdentifier}` + precheckURL, userData?.id, token)
        .then((response) => {
          setPrecheckStatus({
            error: false,
            statusCode: 200,
            success: true,
            data: response.data,
            userData: userData,
          });
          setAwaitResponse({ isLoading: false, msg: "" });
        })
        .catch((error) => {
          setPrecheckStatus({
            error: true,
            statusCode: error?.response?.status,
            success: false,
            data: error?.message,
            userData: userData,
          });
          if (error?.response?.status != 404) {
            setOnBoardingError(true);
          }

          setAwaitResponse({ isLoading: false, msg: "" });
        });
    }
  };

  const handle2FAModalClose = () => setShowModal(false);
  if (!OrgList?.isFetchingOrgs && identityUser) {
    return (
      <>
        <div className={theme} id="edit-uder-top-wrapper" data-testid="edit-user-component">
          <Grid md={3} sx={{ maxWidth: "20%" }} className="ErrorMessageGrid">
            {error && <div className="global-error-msg">{globalErrorMsg}</div>}
          </Grid>
          <LeftDrawerLayout isLeftDrawerOpen={isLeftDrawerOpen} setIsLeftDrawerOpen={setIsLeftDrawerOpen}>
            <Typography
              variant="h4"
              style={isLeftDrawerOpen ? { marginLeft: "13vw" } : { marginLeft: "4vw" }}
              sx={{
                fontSize: "22px",
                fontFamily: "Nunito",
                display: "flex",
                alignSelf: "center",
                marginTop: "1vw",
                marginBottom: "1vw",
              }}
            >
              Edit User
            </Typography>
            <Paper
              className="edit-user-paper"
              elevation={3}
              style={isLeftDrawerOpen ? { marginLeft: "13vw", width: "79vw" } : { width: "88vw", marginLeft: "4vw" }}
            >
              <Container maxWidth={false}>
                <form onSubmit={formik.handleSubmit}>
                  <Grid container columnSpacing={{ xs: 1, sm: 2, md: 1, lg: 3 }} sx={{ mb: "5%" }}>
                    <Grid item xs={12} sm={4} md={4} lg={2}>
                      <FormControl component="fieldset">
                        <label className="input-label" aria-labelledby="organization">
                          Organization<span className="required-star">*</span>
                        </label>
                        <OrganizationTreeSturctureMeta
                          name="organization"
                          mode="edit"
                          OrgList={OrgList}
                          baseOrganizationId={userData?.organization}
                          tenant={tenantSvc?.tenantId}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={2} sx={{ mb: "15px" }}>
                      <FormControl component="fieldset">
                        <label className="input-label">
                          Given name<span className="required-star">*</span>
                          <Tooltip title={nameInfoTooltip}>
                            <InfoIcon
                              sx={{
                                width: 14,
                                height: 14,
                                color: "#2D8282",
                                verticalAlign: "middle",
                                mb: 0.3,
                                ml: "5px",
                              }}
                            />
                          </Tooltip>
                        </label>
                        <TextField
                          name="givenName"
                          variant="outlined"
                          size="small"
                          placeholder="Enter"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.givenName}
                        />
                        {formik.touched.givenName && formik.errors.givenName ? (
                          <div className="error-msg">{formik.errors.givenName}</div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={2}>
                      <FormControl component="fieldset">
                        <label className="input-label">
                          Family name<span className="required-star">*</span>
                          <Tooltip title={nameInfoTooltip}>
                            <InfoIcon
                              sx={{
                                width: 14,
                                height: 14,
                                color: "#2D8282",
                                verticalAlign: "middle",
                                mb: 0.3,
                                ml: "5px",
                              }}
                            />
                          </Tooltip>
                        </label>
                        <TextField
                          name="familyName"
                          variant="outlined"
                          size="small"
                          placeholder="Enter"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.familyName}
                        />
                        {formik.touched.familyName && formik.errors.familyName ? (
                          <div className="error-msg">{formik.errors.familyName}</div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={2}>
                      <FormControl component="fieldset">
                        <label className="input-label">
                          User name
                          <Tooltip title={tenantSvc?.tenantId === "psa" ? userNameInfoToppTip : userNameInfo}>
                            <InfoIcon
                              sx={{
                                width: 14,
                                height: 14,
                                color: "#2D8282",
                                verticalAlign: "middle",
                                mb: 0.3,
                                ml: "5px",
                              }}
                            />
                          </Tooltip>
                        </label>
                        <TextField
                          name="name"
                          variant="outlined"
                          size="small"
                          placeholder="Enter"
                          disabled
                          value={formik.values.name}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={2} data-testid="email-container">
                      <FormControl component="fieldset">
                        <label className="input-label">
                          Email<span className="required-star">*</span>
                        </label>
                        <TextField
                          name="email"
                          variant="outlined"
                          size="small"
                          placeholder="Enter"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          disabled={identityUserClass}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="error-msg">{formik.errors.email}</div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={2} data-testid="phone-container">
                      <FormControl component="fieldset">
                        <label className="input-label">
                          TELEPHONE
                          {tenantSvc?.featureToggle?.isPhoneRequired && <span className="required-star">*</span>}
                          <Tooltip title={telephoneInfoTip}>
                            <InfoIcon
                              sx={{
                                width: 14,
                                height: 14,
                                color: "#2D8282",
                                verticalAlign: "middle",
                                mb: 0.3,
                                ml: "5px",
                              }}
                            />
                          </Tooltip>
                        </label>
                        <TextField
                          name="phone"
                          variant="outlined"
                          size="small"
                          placeholder="Enter"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <div className="error-msg">{formik.errors.phone}</div>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    justifyContent={tenantSvc?.featureToggle?.isEntityPresent ? "center" : "flex-start"}
                    alignItems="start"
                    sx={{ mt: "0px" }}
                    className="grid-2"
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={tenantSvc?.featureToggle?.isEntityPresent ? 6 : 12}
                      lg={tenantSvc?.featureToggle?.isEntityPresent ? 4 : 6}
                    >
                      <label className="input-label">
                        SELECT ROLES
                        <Tooltip title="Multiple records can be selected and transferred">
                          <InfoIcon
                            sx={{
                              width: 14,
                              height: 14,
                              color: "#2D8282",
                              verticalAlign: "middle",
                              mb: 0.3,
                              ml: "5px",
                            }}
                          />
                        </Tooltip>
                      </label>
                      {!loadingRoles ? (
                        <div
                          onClick={(e) =>
                            handleDualListBoxClick(e, e.target, roles?.data, setClickedRoleValue, setClickedEntityValue)
                          }
                        >
                          <DualListBox
                            name="roles"
                            canFilter
                            label="roles"
                            options={roleOptions}
                            selected={selectedRoles}
                            onChange={(selected) => {
                              if (!identityUserClass) {
                                formik.setFieldValue("roles", selected);
                                setSelectedRoles(selected);
                                setIsSaveEnabled(true);
                              }
                            }}
                            className={identityUserClass && "identity-user-disable"}
                            style={{
                              backgroundColor: "#D2EDFF",
                              padding: "5px",
                            }}
                          />
                          {tenantSvc?.featureToggle?.showRolesDescription && (
                            <TextField
                              className="role-description"
                              fullWidth
                              placeholder="Description of a role"
                              value={clickedRoleValue}
                              minRows={1}
                              maxRows={3}
                              multiline
                            />
                          )}
                        </div>
                      ) : (
                        <DualListBoxSkeleton />
                      )}
                    </Grid>
                    {tenantSvc?.featureToggle?.isEntityPresent && (
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <label className="input-label">
                          SELECT ENTITIES
                          <Tooltip title="Multiple records can be selected and transferred">
                            <InfoIcon
                              sx={{
                                width: 14,
                                height: 14,
                                color: "#2D8282",
                                verticalAlign: "middle",
                                mb: 0.3,
                                ml: "5px",
                              }}
                            />
                          </Tooltip>
                        </label>
                        {!loadingRoles ? (
                          <div
                            onClick={(e) =>
                              handleDualListBoxClick(
                                e,
                                e.target,
                                roles?.data,
                                setClickedRoleValue,
                                setClickedEntityValue
                              )
                            }
                          >
                            <DualListBox
                              name="entites"
                              canFilter
                              id="entities"
                              label="entities"
                              options={entitiesOptions}
                              selected={selectedEntities}
                              onChange={(selected) => {
                                if (!identityUserClass) {
                                  formik.setFieldValue("entities", selected);
                                  setSelectedEntities(selected);
                                  setIsSaveEnabled(true);
                                }
                              }}
                              className={identityUserClass && "identity-user-disable"}
                              style={{
                                backgroundColor: "#D2EDFF",
                                padding: "5px",
                              }}
                            />
                            {tenantSvc?.featureToggle?.showRolesDescription && (
                              <TextField
                                className="role-description"
                                fullWidth
                                placeholder="Description of an entity"
                                value={clickedEntityValue}
                                minRows={1}
                                maxRows={3}
                                multiline
                              />
                            )}
                          </div>
                        ) : (
                          <DualListBoxSkeleton />
                        )}
                      </Grid>
                    )}
                    <Grid item xs={12} sm={9} md={6} lg={2} className="Description-box">
                      <label className="input-label">Description</label>
                      <TextField
                        id="decription"
                        multiline
                        rows={9}
                        placeholder="Enter"
                        variant="outlined"
                        sx={{ width: 1 }}
                        label=""
                        name="description"
                        maxLength="250"
                        inputProps={{
                          maxLength: 500,
                        }}
                        value={formik.values.description}
                        helperText={`${formik.values.description.length}/500`}
                        onChange={formik.handleChange}
                        FormHelperTextProps={{ style: { margin: "1% 0 0 92%" } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} md={6} lg={2} sx={{ padding: "16px 30px" }} className="Status-box">
                      <FormControl component="fieldset">
                        <label className="input-label">Status</label>
                        <RadioGroup
                          aria-label="status"
                          name="active"
                          value={formik.values.active}
                          onChange={formik.handleChange}
                          className="Statusbutton"
                        >
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label="Active"
                            disabled={identityUserClass}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label="Inactive"
                            disabled={identityUserClass}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "start", paddingTop: "0px !important", marginTop: "5px" }}
                  >
                    <span>
                      <Button
                        style={userData.active ? { textDecoration: "none", fontSize: "14px" } : {}}
                        onClick={() => handle2FAModalOpen()}
                        disabled={!userData.active}
                        className="manage-two-fa"
                      >
                        Manage 2FA
                      </Button>

                      {userData.active ? (
                        <KeyboardArrowRightIcon
                          sx={{ verticalAlign: "middle", fontSize: "large" }}
                          className="managae-two-fa-arrow"
                        />
                      ) : (
                        <Tooltip title={twoFAInfoToolTip}>
                          <InfoIcon sx={{ width: 14, height: 14, color: "#2D8282", verticalAlign: "middle" }} />
                        </Tooltip>
                      )}
                    </span>
                    <>
                      <OnBoardPrecheckContent
                        preCheckStatus={preCheckStatus}
                        open={showModal}
                        onClose={handle2FAModalClose}
                        awaitResponse={awaitResponse}
                        setAwaitResponse={setAwaitResponse}
                        setShowModal={setShowModal}
                        showModal={showModal}
                        setConfirmResetModal={setConfirmResetModal}
                        confirmResetModal={confirmResetModal}
                        onBoardingError={onBoardingError}
                        setOnBoardingError={setOnBoardingError}
                      />
                    </>
                  </Grid>

                  <Accordion sx={{ marginTop: 2 }} className="more-info-accordian">
                    <AccordionSummary
                      onClick={toggleMoreInfo}
                      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
                      aria-controls="panel1-content"
                      id="more-info-panel-header"
                      sx={{
                        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                          transform: "rotate(90deg)",
                        },
                        flexDirection: "row-reverse",
                      }}
                    >
                      MORE INFO
                    </AccordionSummary>
                    <AccordionDetails>
                      {isMoreInfoVisible && (
                        <div className="more-info-container">
                          <CreatedByInfo id={id} user={userData} />
                          <ModifiedByInfo id={id} user={userData} />
                        </div>
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <hr />

                  <Grid container spacing={1} sx={{ alignItems: "right", display: "flex", justifyContent: "flex-end" }}>
                    <Grid item xs={3} sm={4} md={1} lg={1} className="toolbar-btn cancel-btn">
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{ borderRadius: "20px" }}
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={3} sm={2} md={1} lg={1} className="toolbar-btn" sx={{ justifyContent: "left" }}>
                      {formik.isSubmitting ? (
                        <LoadingButton
                          className="loading-btn"
                          loading
                          loadingPosition="start"
                          startIcon={<SaveIcon />}
                          variant="outlined"
                          sx={{ borderRadius: "20px", padding: "0 30px", maxWidth: "100px" }}
                        >
                          Save
                        </LoadingButton>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          className="save-btn"
                          sx={{ borderRadius: "20px", padding: "0 30px" }}
                          onClick={formik.handleSubmit}
                          disabled={!formik.dirty || formik.isSubmitting}
                        >
                          Save
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </Container>
            </Paper>
          </LeftDrawerLayout>
        </div>
      </>
    );
  } else {
    return <AddEditUserSkeleton />;
  }
};

export default EditUserComponent;
