import { Route, BrowserRouter, Routes } from "react-router-dom";
import { UserListPage } from "../../pages/UserListPage";
import { CreateUserPage } from "../../pages/CreateUserPage";
import { EditUserPage } from "../../pages/EditUserPage";
import DashBoardPage from "../../pages/DashBoardPage/DashBoardPage";
import ProfilePage from "../../pages/ProfilePage/ProfilePage";
import { CredMgmtSetPasswordPage } from "../../pages/CredMgmtSetPasswordPage";
import CredMgmtForgotPasswordPage from "../../pages/CredMgmtForgotPasswordPage/CredMgmtForgotPasswordPage";
import { Roles } from "./Roles";
import RoleAssignmentReportPage from "../../pages/RoleAssignmentReportPage/RoleAssignmentReportPage";
import { Footer } from "../Footer";
import UserLastLoginReportPage from "../../pages/UserLastLoginReportPage/UserLastLoginReportPage";
import AccessLogReportPage from "../../pages/AccessLogReportPage/AccessLogReportPage";
import { HotkeysWrapper } from "../../helpers";

// Create a wrapper for hotkeys
function SecApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<HotkeysWrapper />}>
          <Route
            path="/ciam/v1/:tenant/profile"
            element={
              <ProfilePage
                allowedRoles={[
                  Roles.ADMIN,
                  Roles.PROFILE_VIEWER,
                  Roles.PROFILE_VIEWER_MT,
                  Roles.ADMIN_MT,
                  Roles.ACCESS_LOG_VIEWER_MT,
                ]}
              />
            }
          />

          <Route
            path="/ciam/v1/:tenant/users/create"
            element={<CreateUserPage allowedRoles={[Roles.ADMIN, Roles.ADMIN_MT]} />}
          />
          <Route
            path="/ciam/v1/:tenant/users/edit/:id"
            element={<EditUserPage allowedRoles={[Roles.ADMIN, Roles.ADMIN_MT]} />}
          />
          <Route
            path="/ciam/v1/:tenant"
            element={
              <DashBoardPage
                allowedRoles={[Roles.ADMIN, Roles.PROFILE_VIEWER, Roles.ADMIN_MT, Roles.PROFILE_VIEWER_MT]}
              />
            }
          />
          <Route
            path="/ciam/v1/:tenant/users"
            element={<UserListPage allowedRoles={[Roles.ADMIN, Roles.ADMIN_MT]} />}
          />
          <Route
            path="/ciam/v1/:tenant/reports/role-assignment"
            element={
              <RoleAssignmentReportPage
                allowedRoles={[Roles.ROLE_ASSIGNMENT_VIEWER, Roles.ROLE_ASSIGNMENT_VIEWER_PSA]}
              />
            }
          />
          <Route
            path="/ciam/v1/:tenant/reports/user-last-login"
            element={
              <UserLastLoginReportPage
                allowedRoles={[Roles.LAST_LOGIN_USER_VIEWER, Roles.PSA_LAST_LOGIN_USER_VIEWER]}
              />
            }
          />
          <Route
            path="/ciam/v1/:tenant/reports/access-log"
            element={<AccessLogReportPage allowedRoles={[Roles.ACCESS_LOG_VIEWER_PSA, Roles.ACCESS_LOG_VIEWER_MT]} />}
          />
          <Route path="/ciam/v1/:tenant/accounts/activate" element={<CredMgmtSetPasswordPage />} />
          <Route path="/ciam/v1/:tenant/accounts/change-password" element={<CredMgmtSetPasswordPage />} />
          <Route path="/ciam/v1/:tenant/accounts/forgot-password" element={<CredMgmtForgotPasswordPage />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default SecApp;
