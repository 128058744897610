import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import "./ShortcutsModal.css";
const KeyboardShortcut = ({ shortcut }) => {
  const keys = shortcut.split("+").map((key) => key.trim());

  return (
    <Typography component="span">
      {keys.map((key, index) => (
        <span key={index}>
          <code className="key-cell">{key}</code>
          {index < keys.length - 1 && <span className="key-separator"> then </span>}
        </span>
      ))}
    </Typography>
  );
};

function ShortcutsModal({ open, onClose, hotkeysEnabled, setHotkeysEnabled }) {
  const navigationShortcuts = [
    { key: "l", description: "Go to list page" },
    { key: "c", description: "Go to create user page" },
    { key: "p", description: "Go to profile page" },
    { key: "n", description: "Open left navigation panel" },
    { key: "d", description: "Go to dashboard" },
    // { key: "r + a", description: "Go to access log report" },
    // { key: "r + s", description: "Go to role assignment report page" },
    // { key: "r + l", description: "Go to last login report page" },
    { key: "Alt + l", description: "Logout" },
  ];

  return (
    <Modal open={open} onClose={onClose}>
      <Paper className="paper-shortcuts">
        <Box className="thead-shortcuts">
          <Typography variant="h5">&nbsp; &nbsp;Keyboard shortcuts</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Box>

        <Box sx={{ p: 1 }}>
          <Table className="table-shortcuts">
            <TableHead>
              <TableRow>
                <TableCell className="description-cell-head">Description</TableCell>
                <TableCell className="key-cell-head">Shortcut</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tbody-shortcuts">
              {navigationShortcuts.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className="description-cell">{item.description}</TableCell>
                  <TableCell>
                    <Typography component="code">
                      <KeyboardShortcut shortcut={item.key} />
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Box sx={{ mt: 2, borderRadius: 1 }}>
            <Stack direction="row" alignItems="center" spacing={1} sx={{ justifyContent: "space-between", p: 1 }}>
              <Typography variant="body2" sx={{ bgcolor: "grey.100", padding: "5px" }}>
                Press <code className="shortcut-key-que">?</code> to quickly open or close this dialog at any time
              </Typography>
              <Typography
                variant="body2"
                onClick={() => setHotkeysEnabled((prevValue) => !prevValue)}
                className="toggle-switch"
              >
                {hotkeysEnabled ? "Disable shortcuts" : "Enable shortcuts"}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

export default ShortcutsModal;

ShortcutsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  hotkeysEnabled: PropTypes.bool,
  setHotkeysEnabled: PropTypes.func,
};
KeyboardShortcut.propTypes = {
  shortcut: PropTypes.string,
};
