import { Card, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import "./DashBoard.css";
import { UseIdentityUser } from "../../helpers/UseIdentityUser";
import LeftDrawerLayout from "../LeftDrawerLayout/LeftDrawerLayout";

function DashBoard() {
  var [date, setDate] = useState(new Date());
  const { identityUser } = UseIdentityUser();
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });
  const time = date.getHours();
  const locale = [].concat(navigator.languages);
  const weekDay = date.toLocaleDateString(locale, { weekday: "long" });
  let greetMsg = "";
  if (time >= 1 && time < 12) {
    greetMsg = "morning";
  } else if (time >= 12 && time < 18) {
    greetMsg = "afternoon";
  } else {
    greetMsg = "evening";
  }

  return (
    <>
      <LeftDrawerLayout isLeftDrawerOpen={isLeftDrawerOpen} setIsLeftDrawerOpen={setIsLeftDrawerOpen}>
        <div
          className="user-list-table"
          style={isLeftDrawerOpen ? { marginLeft: "13vw", width: "82vw" } : { marginLeft: "3vw", width: "92vw" }}
        >
          <Card
            data-testid="tenant-context-provider"
            className="dashboardaPage"
            sx={{
              padding: "2% 0 20% 2%",
              mt: "2%",
            }}
          >
            <Typography variant="h4">
              Good {greetMsg}, {identityUser && identityUser?.givenName + " " + identityUser?.familyName}
            </Typography>
            <p data-testid="date-time">
              {weekDay} , {date.toLocaleTimeString({ month: "long" })} , {date.toLocaleDateString()}
            </p>
          </Card>
        </div>
      </LeftDrawerLayout>
    </>
  );
}

export default DashBoard;
