import { useState, useEffect, useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { FormatDateTimeCSV, HandleapiError, TransformJsonDataUtil } from "../../helpers";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { FormatDateTimeReport } from "../../helpers/FormatDateTime/FormatDateTime";
import LeftDrawerLayout from "../LeftDrawerLayout/LeftDrawerLayout";

const BaseReport = ({
  title,
  getDataService,
  tableComponent: TableComponent,
  formComponent: FormComponent,
  roleService,
  callDataSvc,
  classname,
  setCallDataSvc,
  transformData,
}) => {
  const { tenant } = useParams();
  const theme = `${tenant}-theme`;
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState({ before: 0, after: 0 });
  const [formData, setFormData] = useState({});
  const [openError, setOpenError] = useState(false);
  const fetchData = async () => {
    setData([]);
    setError(null);
    try {
      setDataLoading(true);
      const response = await getDataService(roleService, formData);
      setRawData(response?.data);
      setData(transformData ? TransformJsonDataUtil(response?.data) : response?.data);
      setError(null);
    } catch (error) {
      const errorMessage = HandleapiError(error);
      setError(errorMessage);
      setOpenError(true);
    } finally {
      setDataLoading(false);
      setCallDataSvc(false);
    }
  };
  useEffect(() => {
    if (callDataSvc) {
      fetchData();
    }
  }, [callDataSvc, formData]);
  const handleFormSubmit = (newFormData) => {
    setFormData(newFormData);
    if (!callDataSvc) {
      fetchData();
    }
  };

  const formatData = (data, formatter) => {
    return data.map((item) => {
      const newItem = { ...item };
      if (item.lastLoginTime) {
        newItem.lastLoginTime = formatter(item.lastLoginTime);
      }
      if (item.time) {
        newItem.time = formatter(item.time);
      }
      return newItem;
    });
  };

  const mappedData = useMemo(() => formatData(data, FormatDateTimeReport), [data]);
  const csvData = useMemo(() => formatData(data, FormatDateTimeCSV), [data]);

  return (
    <div className={theme} id="base-report-wrapper" data-testid="base-report-wrapper">
      <Grid md={3} sx={{ maxWidth: "20%" }} className="ErrorMessageGrid">
        {error && openError && (
          <div className="global-error-msg">
            <div className="report-error-container">
              <div>{error}</div>
              <div className="close-icn" onClick={() => setOpenError(false)}>
                <CloseIcon fontSize="small" />
              </div>
            </div>
          </div>
        )}
      </Grid>

      <LeftDrawerLayout isLeftDrawerOpen={isLeftDrawerOpen} setIsLeftDrawerOpen={setIsLeftDrawerOpen}>
        <Typography
          variant="h4"
          style={isLeftDrawerOpen ? { marginLeft: "13vw" } : { marginLeft: "4vw" }}
          sx={{
            fontSize: "22px",
            fontFamily: "Nunito",
            display: "flex",
            alignSelf: "center",
            marginTop: "1vw",
            marginBottom: "1vw",
          }}
        >
          {title}
          {data.length > 0 && ` - ${totalData.after} ${totalData.after === 1 ? "Record" : "Records"} `}
        </Typography>
        {FormComponent && (
          <div
            className="form-wrapper"
            style={isLeftDrawerOpen ? { width: "85vw", marginLeft: "11.5vw" } : { width: "94vw", marginLeft: "2.5vw" }}
          >
            <FormComponent onSubmit={handleFormSubmit} dataLoading={dataLoading} />
          </div>
        )}

        <Grid
          container
          spacing={1}
          className="report-table-wrapper"
          data-testid="report-table"
          style={isLeftDrawerOpen ? { width: "86vw", marginLeft: "10.5vw" } : { width: "95vw", marginLeft: "1vw" }}
        >
          <Grid item xs={12} className={classname}>
            <TableComponent
              data={mappedData}
              dataLoading={dataLoading}
              csvData={csvData}
              setTotalData={setTotalData}
              rawData={rawData}
            />
          </Grid>
        </Grid>
      </LeftDrawerLayout>
    </div>
  );
};

BaseReport.propTypes = {
  title: PropTypes.string.isRequired,
  getDataService: PropTypes.func.isRequired,
  tableComponent: PropTypes.elementType.isRequired,
  formComponent: PropTypes.elementType,
  roleService: PropTypes.object.isRequired,
  callDataSvc: PropTypes.bool,
  classname: PropTypes.string,
  setCallDataSvc: PropTypes.func,
  transformData: PropTypes.func,
};

export default BaseReport;
