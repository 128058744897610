import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./AccessDeniedPage.css";

function AccessDeniedPage() {
  const navigate = useNavigate();
  const { tenant } = useParams();
  return (
    <div className="access-denied-wrapper">
      <div className="access-denied-copmonent">
        <h1>Access Denied</h1>
        <p>You do not have permission to access this page.</p>
        <Button variant="outlined" color="primary" onClick={() => navigate(`/ciam/v1/${tenant}`)}>
          Go to Dashboard
        </Button>
      </div>
    </div>
  );
}

export default AccessDeniedPage;
