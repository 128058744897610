const FaviconLoader = (url) => {
  if (typeof window === "undefined") return null;

  const setFavicon = (faviconUrl) => {
    let link = document.querySelector("link[rel*='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.head.appendChild(link);
    }
    link.href = faviconUrl;
  };

  const defaultFavicon = `${process.env.PUBLIC_URL}/static/images/default/favicon.png`;

  const testFavicon = (faviconUrl, fallbackUrl) => {
    const img = new Image();
    img.src = faviconUrl;

    img.onload = () => setFavicon(faviconUrl);
    img.onerror = () => setFavicon(fallbackUrl);
  };

  const faviconUrl = new URL(String(url), window.location.origin).href;
  testFavicon(faviconUrl, defaultFavicon);

  return null;
};

export default FaviconLoader;
