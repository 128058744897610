import { Box } from "@mui/material";
import PropTypes from "prop-types";
import "./LogoBox.css";

const LogoBox = ({ tenant, fallbackSrc }) => {
  const defaultSrc = `${process.env.PUBLIC_URL}/static/images/default/banner_logo.png`;
  const src = `${process.env.PUBLIC_URL}/static/images/${tenant}/banner_logo.png`;

  return (
    <Box
      component="img"
      alt="logo"
      margin="10px"
      src={src}
      data-testid="logo"
      className="logo-dynamic"
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = fallbackSrc || defaultSrc;
      }}
    />
  );
};

LogoBox.propTypes = {
  tenant: PropTypes.string,
  fallbackSrc: PropTypes.string,
};
export default LogoBox;
