import {
  Drawer,
  ListItem,
  ListItemText,
  Box,
  Stack,
  List,
  Collapse,
  IconButton,
  Typography,
  ListItemButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { useState, useEffect } from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import PropTypes from "prop-types";
import "./LeftDrawerList.css";
import { useAuth } from "../../contexts/AuthContext";
import { Roles } from "../SecApp/Roles";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ShortcutsModal from "../ShortcutsModal/ShortcutsModal";
import KeyboardOutlinedIcon from "@mui/icons-material/KeyboardOutlined";
import { useHotkeys } from "react-hotkeys-hook";
import { useHotkeysContext } from "../../contexts/HotKeysContext";
import RoleBasedReportsMenu from "../RoleBasedReportsMenu/RoleBasedReportsMenu";
function LeftDrawerList({ isLeftDrawerOpen, setIsLeftDrawerOpen }) {
  const [openSubMenu, setOpenSubMenu] = useState(true);
  const [selectedItem, setSelectedItem] = useState("Dashboard");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { hotkeysEnabled, setHotkeysEnabled } = useHotkeysContext();
  const handleSubMenuClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const navigate = useNavigate();
  const { authService } = useAuth();
  const { tenant } = useParams();
  const { id } = useParams("id");
  const dashboardURL = `/ciam/v1/${tenant}`;
  const userURL = `/ciam/v1/${tenant}/users`;
  const createUserURL = `/ciam/v1/${tenant}/users/create`;
  const editUserURL = `/ciam/v1/${tenant}/users/edit/${id}`;
  const profileURL = `/ciam/v1/${tenant}/profile`;
  const accessLogReportURL = `/ciam/v1/${tenant}/reports/access-log`;
  const roleAssignmentReportURL = `/ciam/v1/${tenant}/reports/role-assignment`;
  const userLastLoginURL = `/ciam/v1/${tenant}/reports/user-last-login`;
  const navigateTo = (path) => {
    navigate(path);
  };
  useHotkeys(
    ["shift+?", "?"],
    (event) => {
      event.preventDefault();
      setIsModalOpen((prev) => !prev);
    },
    { preventDefault: true, enabled: hotkeysEnabled }
  );
  useHotkeys(
    "n",
    (event) => {
      event.preventDefault();
      setIsLeftDrawerOpen((prev) => !prev);
    },
    { preventDefault: true, enabled: hotkeysEnabled }
  );
  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath === dashboardURL) {
      setSelectedItem("Dashboard");
    } else if (currentPath === userURL || currentPath === createUserURL || currentPath == editUserURL) {
      setSelectedItem("Users");
    } else if (currentPath === accessLogReportURL) {
      setSelectedItem("Access log");
    } else if (currentPath === roleAssignmentReportURL) {
      setSelectedItem("Role assignment");
    } else if (currentPath === userLastLoginURL) {
      setSelectedItem("User last login");
    } else if (currentPath === profileURL) {
      setSelectedItem("");
    }
  }, [dashboardURL, userURL, accessLogReportURL, profileURL]);

  return (
    <Box sx={{ bgcolor: "#FAFCFE" }}>
      <Drawer
        data-testid="left-drawer"
        anchor="left"
        open={isLeftDrawerOpen}
        variant="permanent"
        onClose={() => isLeftDrawerOpen(false)}
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            overflowX: "hidden",
            width: isLeftDrawerOpen ? "max-content" : "50px",
          },
          "& .MuiPaper-root": {
            backgroundColor: "#FAFCFE",
          },
        }}
      >
        <nav aria-label="main mailbox folders">
          <Stack
            sx={{
              marginTop: "100px",
              fontFamily: "Calibri",
              fontSize: "16px",
              color: "#778FA5",
              "& .RaMenuItemLink-active": {
                color: "#0099FF",
                fontWeight: "bold",
              },
            }}
          >
            {isLeftDrawerOpen && (
              <ListItem sx={{ justifyContent: "flex-end", cursor: "pointer", mb: 1 }}>
                <ArrowBackIosNewIcon
                  className="back-arrow-icon"
                  onClick={() => {
                    setIsLeftDrawerOpen(false);
                  }}
                />
              </ListItem>
            )}
            {!isLeftDrawerOpen && (
              <ListItem sx={{ mb: 1 }}>
                <IconButton edge="start" onClick={() => setIsLeftDrawerOpen(true)} sx={{ paddingLeft: "15px" }}>
                  <Typography
                    component="img"
                    height="15px"
                    src={process.env.PUBLIC_URL + "/static/images/drawer_menu_icon.png"}
                    className="expand-left-bar-icon"
                  />
                </IconButton>
              </ListItem>
            )}
            <ListItem
              onClick={() => {
                setSelectedItem("Dashboard");
                navigateTo(dashboardURL);
              }}
              className="menu_list_item"
              sx={{ paddingLeft: "0px" }}
            >
              <Tooltip placement="right-start" title={!isLeftDrawerOpen && "Dashboard"}>
                <DashboardCustomizeOutlinedIcon
                  className={`dashboard-icon side-menu-icon ${selectedItem === "Dashboard" ? "selected" : ""}`}
                />
              </Tooltip>
              {isLeftDrawerOpen && (
                <ListItemText primary="Dashboard" className={selectedItem === "Dashboard" ? "selected" : ""} />
              )}
            </ListItem>
            {authService?.authorize([Roles.ADMIN, Roles.ADMIN_MT]) && (
              <ListItem
                onClick={() => {
                  setSelectedItem("Users");
                  setIsLeftDrawerOpen(false);
                  navigateTo(userURL);
                }}
                className="menu_list_item"
                sx={{ paddingLeft: "0px" }}
              >
                <Tooltip placement="right-start" title={!isLeftDrawerOpen && "Users"}>
                  <Groups2OutlinedIcon
                    className={`users-icon side-menu-icon ${selectedItem === "Users" ? "selected" : ""}`}
                  />
                </Tooltip>

                {isLeftDrawerOpen && (
                  <ListItemText primary="Users" className={selectedItem == "Users" ? "selected" : ""} />
                )}
              </ListItem>
            )}
            {authService?.authorize([
              Roles.ACCESS_LOG_VIEWER,
              Roles.ACCESS_LOG_VIEWER_MT,
              Roles.ROLE_ASSIGNMENT_VIEWER,
              Roles.LAST_LOGIN_USER_VIEWER,
              Roles.PSA_LAST_LOGIN_USER_VIEWER,
              Roles.ROLE_ASSIGNMENT_VIEWER_PSA,
            ]) && (
              <ListItem
                onClick={() => {
                  handleSubMenuClick();
                }}
                className="menu_list_item"
                sx={{ paddingLeft: "0px" }}
              >
                <Tooltip
                  placement="right-start"
                  leaveDelay={2000}
                  componentsProps={{
                    tooltip: {
                      className: "reports-popover",
                    },
                  }}
                  title={
                    !isLeftDrawerOpen && (
                      <List sx={{ bgcolor: "#fff", borderRadius: 1 }}>
                        <ListItemButton>
                          <ListItemText primary="Reports" />
                        </ListItemButton>
                        <Divider />
                        <RoleBasedReportsMenu
                          selectedItem={selectedItem}
                          setSelectedItem={setSelectedItem}
                          navigateTo={navigateTo}
                        />
                      </List>
                    )
                  }
                >
                  <AssessmentOutlinedIcon
                    className={`assessment-icon side-menu-icon ${
                      selectedItem === "Access log" ||
                      selectedItem === "User last login" ||
                      selectedItem === "Role assignment"
                        ? "selected"
                        : ""
                    }`}
                  />
                </Tooltip>

                {isLeftDrawerOpen && (
                  <>
                    <ListItemText
                      primary="Reports"
                      onClick={() => setIsLeftDrawerOpen(true)}
                      className={` ${
                        selectedItem === "Access log" ||
                        selectedItem === "User last login" ||
                        selectedItem === "Role assignment"
                          ? "selected"
                          : ""
                      }`}
                    />
                    {openSubMenu ? <ExpandLess /> : <ExpandMore />}
                  </>
                )}
              </ListItem>
            )}
          </Stack>
          {isLeftDrawerOpen && (
            <Collapse in={openSubMenu} timeout="auto" unmountOnExit>
              <RoleBasedReportsMenu
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                navigateTo={navigateTo}
              />
            </Collapse>
          )}
        </nav>

        <Box
          className="keyboard-shortcuts-wrapper"
          sx={{
            "&:hover": {
              backgroundColor: "#0000000F",
            },
          }}
        >
          <a
            onClick={() => {
              setIsModalOpen(!isModalOpen);
            }}
            className="keyboard-shortcuts-link"
          >
            <Tooltip placement="right-start" title={!isLeftDrawerOpen && "Shortcuts help"}>
              <KeyboardOutlinedIcon />
            </Tooltip>
            {isLeftDrawerOpen && <span>&nbsp;&nbsp; Shortcuts help</span>}
          </a>
        </Box>

        <ShortcutsModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          hotkeysEnabled={hotkeysEnabled}
          setHotkeysEnabled={setHotkeysEnabled}
        />
      </Drawer>
    </Box>
  );
}
LeftDrawerList.propTypes = {
  setIsLeftDrawerOpen: PropTypes.func,
  isLeftDrawerOpen: PropTypes.bool,
};
export default LeftDrawerList;
