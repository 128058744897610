import PropTypes from "prop-types";
import { ListItem, ListItemText } from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import { Roles } from "../SecApp/Roles";
import { useParams } from "react-router-dom";

function RoleBasedReportsMenu({ selectedItem, setSelectedItem, navigateTo }) {
  const { authService } = useAuth();
  const { tenant } = useParams();
  const accessLogReportURL = `/ciam/v1/${tenant}/reports/access-log`;
  const roleAssignmentReportURL = `/ciam/v1/${tenant}/reports/role-assignment`;
  const userLastLoginURL = `/ciam/v1/${tenant}/reports/user-last-login`;

  return (
    <>
      {authService?.authorize([Roles.ACCESS_LOG_VIEWER_PSA, Roles.ACCESS_LOG_VIEWER_MT]) && (
        <ListItem
          button
          onClick={() => {
            setSelectedItem("Access log");
            navigateTo(accessLogReportURL);
          }}
        >
          <ListItemText primary="Access log" className={selectedItem === "Access log" ? "selected" : ""} />
        </ListItem>
      )}
      {authService?.authorize([Roles.ROLE_ASSIGNMENT_VIEWER_PSA, Roles.ROLE_ASSIGNMENT_VIEWER]) && (
        <ListItem
          button
          onClick={() => {
            setSelectedItem("Role assignment");
            navigateTo(roleAssignmentReportURL);
          }}
        >
          <ListItemText primary="Role assignment" className={selectedItem === "Role assignment" ? "selected" : ""} />
        </ListItem>
      )}

      {authService?.authorize([Roles.LAST_LOGIN_USER_VIEWER, Roles.PSA_LAST_LOGIN_USER_VIEWER]) && (
        <ListItem
          button
          onClick={() => {
            setSelectedItem("User last login");
            navigateTo(userLastLoginURL);
          }}
        >
          <ListItemText primary="User last login" className={selectedItem === "User last login" ? "selected" : ""} />
        </ListItem>
      )}
    </>
  );
}

RoleBasedReportsMenu.propTypes = {
  selectedItem: PropTypes.string.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
};

export default RoleBasedReportsMenu;
