import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Roles } from "../../components/SecApp/Roles";
import { useHotkeys } from "react-hotkeys-hook";
import PropTypes from "prop-types";
import { useAuth } from "../../contexts/AuthContext";
import { useHotkeysContext } from "../../contexts/HotKeysContext";

const HotkeysWrapper = () => {
  const navigate = useNavigate();
  const { tenant } = useParams();
  const { authService } = useAuth();
  const { hotkeysEnabled: enabled } = useHotkeysContext();

  // Define navigation routes configuration
  const ROUTES = {
    USERS: `/ciam/v1/${tenant}/users`,
    CREATE_USER: `/ciam/v1/${tenant}/users/create`,
    PROFILE: `/ciam/v1/${tenant}/profile`,
    DASHBOARD: `/ciam/v1/${tenant}`,
    ACCESS_LOG: `/ciam/v1/${tenant}/reports/access-log`,
    ROLE_ASSIGNMENT: `/ciam/v1/${tenant}/reports/role-assignment`,
    USER_LAST_LOGIN: `/ciam/v1/${tenant}/reports/user-last-login`,
  };

  // Define hotkey configurations
  const HOTKEY_CONFIG = [
    {
      key: "l",
      route: ROUTES.USERS,
      allowedRoles: [Roles.ADMIN, Roles.ADMIN_MT],
      description: "Navigate to users",
    },
    {
      key: "c",
      route: ROUTES.CREATE_USER,
      allowedRoles: [Roles.ADMIN, Roles.ADMIN_MT],
      description: "Navigate to create user",
    },
    {
      key: "p",
      route: ROUTES.PROFILE,
      allowedRoles: [Roles.ADMIN, Roles.PROFILE_VIEWER, Roles.PROFILE_VIEWER_MT, Roles.ADMIN_MT],
      description: "Navigate to profile",
    },
    {
      key: "d",
      route: ROUTES.DASHBOARD,
      description: "Navigate to dashboard",
    },
    {
      key: "r+a",
      route: ROUTES.ACCESS_LOG,
      description: "Navigate to access log",
    },
    {
      key: "r+s",
      route: ROUTES.ROLE_ASSIGNMENT,
      description: "Navigate to role assignment",
    },
    {
      key: "r+l",
      route: ROUTES.USER_LAST_LOGIN,
      description: "Navigate to user last login",
    },
  ];
  const hasPermission = (allowedRoles) => {
    const userRoles = [Roles.ADMIN, Roles.PROFILE_VIEWER];
    if (!allowedRoles) return true;

    const result = userRoles.some((role) => allowedRoles.includes(role));

    return result;
  };

  HOTKEY_CONFIG.forEach(({ key, route, allowedRoles }) => {
    useHotkeys(
      key,
      (event) => {
        event.preventDefault();

        if (hasPermission(allowedRoles)) {
          navigate(route);
        }
      },
      {
        preventDefault: true,
        enabled,
      },
      [route, navigate, enabled]
    );
  });

  useHotkeys(
    "Alt+l",
    (event) => {
      event.preventDefault();
      authService.logout();
    },
    { preventDefault: true, enabled },
    [authService]
  );

  return <Outlet />;
};

HotkeysWrapper.propTypes = {
  children: PropTypes.node,
};

export default HotkeysWrapper;
